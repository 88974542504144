/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.journey-section {
  max-width: 1310px;
  padding: 122px 0 50px;
  margin: 0 auto;
}
@media only screen and (max-width: 1366px) {
  .journey-section {
    padding: 60px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .journey-section {
    padding: 60px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .journey-section {
    position: relative;
    padding: 100px 25px 0;
    overflow: hidden;
  }
}
.journey-section .swiper {
  position: static;
}
@media only screen and (max-width: 767px) {
  .journey-section__main-title {
    display: block;
    width: 75%;
  }
}
.journey-section__items {
  display: flex;
  margin-top: 47px;
}
@media only screen and (max-width: 767px) {
  .journey-section__items {
    margin-top: 36px;
  }
}
.journey-section__items .journey-section__item:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .journey-section__items .journey-section__item:last-child {
    margin-bottom: 0;
  }
}
.journey-section__item {
  margin-right: 32px;
  width: calc(25% - 24px);
}
@media only screen and (max-width: 1366px) {
  .journey-section__item {
    margin-right: 14px;
    width: calc(25% - 10px);
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__item {
    margin-bottom: 73px;
    margin-right: 0;
    width: 100%;
  }
}
.journey-section__item-content {
  background-color: #efefef;
  padding: 3px 30px 30px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1366px) {
  .journey-section__item-content {
    padding: 5px 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__item-content {
    padding: 22px 35px 0;
    margin-top: 17px;
    overflow: hidden;
  }
}
@media only screen and (max-width: 550px) {
  .journey-section__item-content {
    min-height: 350px;
  }
}
.journey-section__item-title, .journey-section__item-step {
  display: block;
  font-size: 32px;
  line-height: 36px;
  font-family: "HeadingProTreble-Heavy", sans-serif;
  text-transform: uppercase;
}
.journey-section__item-title {
  color: #2d2d2d;
  margin-top: 7px;
  font-size: 28px;
  line-height: 38px;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .journey-section__item-title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .journey-section__item-title {
    font-size: 18px;
    line-height: 26px;
  }
}
.journey-section__item-step {
  color: transparent;
  -webkit-text-stroke: 1px #313136;
}
.journey-section__item-paragraph, .journey-section__item-btn {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.journey-section__item-paragraph {
  color: #2d2d2d;
  padding-right: 60px;
  margin-top: 10px;
  line-height: 22px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .journey-section__item-paragraph {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__item-paragraph {
    padding-right: 0;
    margin-top: 8px;
    line-height: 27px;
  }
}
.journey-section__item-btn {
  display: block;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  text-align: center;
  margin-top: auto;
  padding: 14px 5px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .journey-section__item-btn {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__item-btn {
    margin: 15px -35px 0;
  }
}
@media only screen and (max-width: 550px) {
  .journey-section__item-btn {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.journey-section__item-image {
  width: 100px;
  height: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .journey-section__item-image {
    width: 80px;
    height: 80px;
  }
}
.journey-section__item-image img {
  width: 100%;
  height: 100%;
}
.journey-section__prev-btn, .journey-section__next-btn {
  top: 60%;
  outline: none;
}
@media only screen and (min-width: 768px) {
  .journey-section__prev-btn, .journey-section__next-btn {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__prev-btn, .journey-section__next-btn {
    background-image: url("../../../img/svg/arrow-down.svg");
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__prev-btn {
    left: 0;
    transform: rotate(90deg);
  }
}
@media only screen and (max-width: 767px) {
  .journey-section__next-btn {
    right: 0;
    transform: rotate(-90deg);
  }
}
// old swiper css:
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.journey-section {
    max-width: 1310px;
    padding: 122px 0 50px;
    margin: 0 auto;

    @include mq($until-dt-med) {
        padding: 60px 50px;
    }

    @include mq($tablet) {
        padding: 60px 20px;
    }

    @include mq($mobile-max) {
        position: relative;
        padding: 100px 25px 0;
        overflow: hidden;
    }

    .swiper {
        position: static;
    }

    &__main-title {
        @include mq($mobile-max) {
            display: block;
            width: 75%;
        }
    }

    &__items {
        display: flex;
        margin-top: 47px;

        @include mq($mobile-max) {
            margin-top: 36px;
        }

        .journey-section__item:last-child {
            margin-right: 0;

            @include mq($mobile-max) {
                margin-bottom: 0;
            }
        }
    }

    &__item {
        margin-right: 32px;
        width: calc(25% - 24px);

        @include mq($until-dt-med) {
            margin-right: 14px;
            width: calc(25% - 10px);
        }

        @include mq($mobile-max) {
            margin-bottom: 73px;
            margin-right: 0;
            width: 100%;
        }
    }

    &__item-content {
        background-color: $white-smoke;
        padding: 3px 30px 30px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;

        @include mq($until-dt-med) {
            padding: 5px 10px 20px;
        }

        @include mq($mobile-max) {
            padding: 22px 35px 0;
            margin-top: 17px;
            overflow: hidden;
        }

        @include mq($mobile-pt-l) {
            min-height: 350px;
        }
    }

    &__item-title,
    &__item-step {
        display: block;
        @include h4;

        font-family: $heading-pro-treble-heavy;
        text-transform: uppercase;
    }

    &__item-title {
        color: $nero;
        margin-top: 7px;
        font-size: 28px;
        line-height: 38px;
        overflow: hidden;

        @include mq($tablet-max) {
            font-size: 24px;
            line-height: 32px;
        }

        @include mq($tablet) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__item-step {
        color: transparent;
        -webkit-text-stroke: 1px $black;
    }

    &__item-paragraph,
    &__item-btn {
        @include body;
    }

    &__item-paragraph {
        color: $nero;
        padding-right: 60px;
        margin-top: 10px;
        line-height: 22px;

        @include mq($tablet) {
            padding-right: 20px;
        }

        @include mq($mobile-max) {
            padding-right: 0;
            margin-top: 8px;
            line-height: 27px;
        }
    }

    &__item-btn {
        display: block;
        font-family: $heading-pro-treble-extra-bold;
        text-align: center;
        margin-top: auto;
        padding: 14px 5px;

        @include mq($tablet) {
            font-size: 14px;
        }

        @include mq($mobile-max) {
            margin: 15px -35px 0;
        }

        @include mq($mobile-pt-l) {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    &__item-image {
        width: 100px;
        height: 100px;

        @include mq($tablet) {
            width: 80px;
            height: 80px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__prev-btn,
    &__next-btn {
        top: 60%;
        outline: none;

        @include mq($not-mobile) {
            display: none;
        }

        @include mq($mobile-max) {
            background-image: url("../../../img/svg/arrow-down.svg");
        }
    }

    &__prev-btn {
        @include mq($mobile-max) {
            left: 0;
            transform: rotate(90deg);
        }
    }

    &__next-btn {
        @include mq($mobile-max) {
            right: 0;
            transform: rotate(-90deg);
        }
    }
}
